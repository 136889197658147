import React from 'react'
import { Spin } from 'antd'

import { Wrapper, Content, CenterContent } from './styles'
import { TLoaderProps } from './types'

const Loader = ({ fixed = true, padding, ...props }: TLoaderProps) => {
    const Spinner = () => {
        return (
            <Content fixed={fixed} padding={padding} {...props}>
                {/* Quando carregado no modal é necesario centralizar 
                para o load não ficar na parte inferior quando modal é muito grande */}
                <CenterContent>
                    <Spin />
                </CenterContent>
            </Content>
        )
    }

    return fixed ? Spinner() : <Wrapper {...props}>{Spinner()}</Wrapper>
}

export default Loader
