import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Contents from '../pages/Contents'
import Categories from '../pages/Categories'
import ContentsImport from '../pages/Contents/ContentsImport'
import PlayersIntegration from '../pages/Integrations/Players'
import PrivateRoute from '@cms/core/routes/privateRoute'

import WatchPlans from '../pages/WatchPlans'
import WatchUsers from '../pages/WatchUsers'
import WatchExperts from '../pages/WatchExperts'
import WatchConfig from '../pages/Config'
import ConfigDomain from '../pages/Config/pages/Domain'
import WatchCourses from '../pages/Courses'
import CourseEditor from '../pages/Courses/CourseEditor'

// prettier-ignore
export default function WatchRoutes() {
    // const customer = useSelector((state) => state.customer) || {}

    return (
        <Switch>
            <PrivateRoute
                checkModulePath
                path="/conteudo/videos/importacao"
                component={ContentsImport}
            />
            <PrivateRoute checkModulePath path="/conteudo/videos" component={Contents} />
            <PrivateRoute checkModulePath path="/conteudo/categorias" component={Categories} />
            <Redirect
                checkModulePath
                exact                
                path="/"
                to={{ pathname: '/usuarios' }}
            />
            <PrivateRoute checkModulePath path="/planos" component={WatchPlans} />
            <PrivateRoute checkModulePath path="/usuarios" component={WatchUsers} />
            <PrivateRoute checkModulePath path="/integracoes/players" component={PlayersIntegration} />
            <PrivateRoute checkModulePath path="/dominio" component={ConfigDomain} />
            <PrivateRoute checkModulePath path="/config" component={WatchConfig} />
            <PrivateRoute checkModulePath path="/especialistas" component={WatchExperts} />
            
            <PrivateRoute checkModulePath path="/cursos/videos" component={() => <Contents module="COURSE" />} />
            <PrivateRoute checkModulePath path="/cursos/adicionar" component={CourseEditor} />
            <PrivateRoute checkModulePath path="/cursos/:courseId/editar" component={CourseEditor} />
            <PrivateRoute checkModulePath path="/cursos" component={WatchCourses} />

        </Switch>
    )
}
