import React from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Layout, Row, Col, Tabs } from 'antd'
import Modal from '../../../../core/components/ModalFull'

import { DashboardTabs } from '@cms/events/components/Dashboard/styles'
import { ButtonReturn } from '@cms/events/components/Buttons'
import Rating, { getOptions } from '@cms/events/components/Rating'
import { CardsComponents } from '@cms/events/components/Dashboard/BlockItens'
import {
    DashboardCard,
    DashboardChat,
    ListOfSpeakers
} from '@cms/events/components/Dashboard'

const { Content } = Layout
const { TabPane } = Tabs

const DashboardTab = ({ data, eventId, components, talkId }) => {
    return (
        <Modal disableFooter={true} visible={true}>
            <Content style={{ marginTop: 0, marginBottom: 0 }}>
                {/* <PageHeader /> */}
                <Row gutter={24}>
                    <Col span={24}>
                        <DashboardTabs
                            defaultActiveKey="1"
                            tabBarExtraContent={{
                                left: <ButtonReturn />
                            }}
                        >
                            <TabPane tab={data.talk.title} key="1">
                                <Row gutter={[24, 24]}>
                                    <Col span={14}>
                                        {components.includes('rating') && (
                                            <Row>
                                                <DashboardCard>
                                                    <Rating
                                                        total={
                                                            data.talk.rating
                                                                .total
                                                        }
                                                        average={
                                                            data.talk.rating
                                                                .average
                                                        }
                                                        options={getOptions(
                                                            data.talk.rating
                                                        )}
                                                    />
                                                </DashboardCard>
                                            </Row>
                                        )}
                                        {components.includes('cards') && (
                                            <Row span={24} justify="center">
                                                <CardsComponents
                                                    eventId={eventId}
                                                    talk={talkId}
                                                    span={6}
                                                />
                                            </Row>
                                        )}
                                        {components.includes('spekears') && (
                                            <Row span={24}>
                                                <Col span={24}>
                                                    <ListOfSpeakers
                                                        id={talkId}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                    {components.includes('chat') && (
                                        <Col span={10}>
                                            <DashboardChat talk={talkId} />
                                        </Col>
                                    )}
                                </Row>
                            </TabPane>
                        </DashboardTabs>
                    </Col>
                </Row>
            </Content>
        </Modal>
    )
}

export default DashboardTab
